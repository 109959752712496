<template>
  <v-container>

    <v-row>
      <v-col cols="12">

        <v-row class="mb-3">
          <v-col>
            <v-btn to="/admin/registrations" color="secondary" small>
              <v-icon class="mr-2">mdi-arrow-left</v-icon>
              Volver
            </v-btn>
          </v-col>
        </v-row>

        <v-card>
          <v-card-title class="pb-0">
            <v-container>

              <v-row>
                <v-col class="mb-4 py-0">
                  <p class="text-h5 mb-0">
                    <v-avatar color="blue" size="38"><v-icon dark>mdi-draw</v-icon></v-avatar>
                    Nuevo Proceso de Inscripción
                  </p>
                </v-col>
              </v-row>

            </v-container>
          </v-card-title>
          
          <v-card-text>
            <v-container>

              <!-- General info inputs -->
              <v-row>
                <v-col>
                  <v-text-field 
                    v-model="newRegistration.name" 
                    label="Nombre del proceso de inscripción" 
                    outlined dense
                    hint="Ej: Inscripciones Talleres Primer Semestre 2022"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="newRegistration.startDate"
                    type="date"
                    label="Fecha de Inicio"
                    outlined dense
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="newRegistration.endDate"
                    type="date"
                    label="Fecha de Término"
                    outlined dense
                  />
                </v-col>
              </v-row>

              <v-divider class="mb-5"></v-divider>

              <!-- Courses Title -->
              <v-row>
                <v-col class="">
                  <p class="text-h6 mb-2">
                    <v-icon>mdi-human-male-board</v-icon>
                    Cursos adheridos
                  </p>
                </v-col>
                <v-col class="text-right">
                  <v-btn @click="createDialog = true" color="success" small class="ml-2 mb-2">
                    <v-icon class="mr-2">mdi-plus</v-icon>
                    Añadir Curso
                  </v-btn>
                  <v-btn @click="importDialog = true" color="info" small class="ml-2 mb-2">
                    <v-icon class="mr-2">mdi-import</v-icon>
                    Importar Cursos
                  </v-btn>
                </v-col>
              </v-row>

              <!-- Courses List -->
              <v-row>
                <v-col cols="12">
                  <v-data-table
                    :headers="headers"
                    :items="newRegistration.courses"
                    disable-pagination
                    hide-default-footer
                  >

                    <template v-slot:item.name="{ item }">
                      {{item.name}}
                    </template>

                    <template v-slot:item.actions="{ item }">
                      <v-icon small class="mr-2" @click="openEditDialog(item)">mdi-pencil</v-icon>
                      <v-icon small class="mr-2" @click="openDeleteDialog(item)">mdi-close</v-icon>
                    </template>

                  </v-data-table>
                </v-col>
              </v-row>

              <v-divider class="my-6"></v-divider>

              <!-- Submit -->
              <v-row>
                <v-col class="text-right">
                  <v-btn @click="saveRegistration()" color="success">
                    <v-icon class="mr-2">mdi-floppy</v-icon>
                    Guardar
                  </v-btn>
                </v-col>
              </v-row>

            </v-container>
          </v-card-text>
        </v-card>

      </v-col>
    </v-row>

    <!-- Dialogs -->
    <CreateRegistrationCourse v-model="createDialog" @insert="addCourse" />
    <EditRegistrationCourse v-model="editDialog" :courseData="editData" @edit="editCourse" />
    <ImportCoursesDialog v-model="importDialog" @import="importCourses" />
    
    <!-- Delete course item -->
    <v-dialog transition="dialog-top-transition" v-model="deleteDialog" max-width="450px" >
      <v-card>

        <v-card-title class="text-body-1">Está seguro de que quiere eliminar este curso?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="deleteDialog = false" >
            Cancelar
          </v-btn>
          <v-btn color="error" dark @click="deleteCourse()">
            Eliminar
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- snack notification -->
    <v-snackbar
      v-model="isSnackbar"
      :color="snackcolor"
      :timeout="2000"
      top
    >
      {{ snacktext }}
    </v-snackbar>

  </v-container>
</template>

<script>
import ImportCoursesDialog from '@/components/admin/ImportCoursesDialog.vue'
import CreateRegistrationCourse from '@/components/admin/CreateRegistrationCourse.vue'
import EditRegistrationCourse from '@/components/admin/EditRegistrationCourse.vue'

export default {
  name: `AdminCreateRegistration`,
  components: {
    ImportCoursesDialog,
    CreateRegistrationCourse,
    EditRegistrationCourse,
  },
  data: () => ({
    //state
    isSaving: false,
    isSnackbar: false,
    snackcolor: 'success',
    snacktext: '',
    importDialog: false,
    createDialog: false,
    editDialog: false,
    editData: {},
    deleteDialog: false,
    deleteId: null,
    //data
    professors: [],
    headers: [
      { text: 'Nombre', value: 'name' },
      { text: 'Profesor', value: 'professorName' },
      { text: 'Email del Profesor', value: 'professorEmail' },
      { text: 'Cupos', value: 'slots' },
      { text: 'Acciones', value: 'actions' },
    ],
    //form
    newRegistration: {
      name: '',
      startDate: null,
      endDate: null,
      courses: []
    }
  }),
  methods: {
    async saveRegistration() {
      try {

        if(!this.newRegistration.name || !this.newRegistration.startDate || !this.newRegistration.endDate || this.newRegistration.courses.length < 1) return this.invalidFormNotification()
        
        this.isSaving = true

        await this.axios.post(`${process.env.VUE_APP_APIURL}/registration`, this.newRegistration)

        this.snackcolor = 'success'
        this.snacktext = 'Proceso de inscripción creado'
        this.isSnackbar = true

        this.$router.push('/admin/registrations')

      } catch (e) {
        console.error(e)
        this.snackcolor = 'error'
        this.snacktext = 'Hubo un problema al crear el proceso de inscripción'
        this.isSnackbar = true
      } finally {
        this.isSaving = false
      }
    },
    importCourses(impCourses) {
      this.newRegistration.courses = impCourses
    },
    addCourse(course) {
      this.newRegistration.courses.push(course)
    },
    openEditDialog(course) {
      this.editData = course
      this.editDialog = true
    },
    openDeleteDialog(id) {
      this.deleteId = id
      this.deleteDialog = true
    },
    editCourse(editedData) {
      let foundIdx = this.newRegistration.courses.findIndex(course => course == this.editData)

      if(foundIdx !== -1) {
        Object.assign(this.newRegistration.courses[foundIdx], editedData)
      }

    },
    deleteCourse() {
      this.newRegistration.courses = this.newRegistration.courses.filter(course => course !== this.deleteId)
      this.deleteDialog = false
    },
    invalidFormNotification() {
      this.snackcolor = 'error'
      this.snacktext = 'Por favor complete los campos requeridos'
      this.isSnackbar = true
    },
  },
}
</script>